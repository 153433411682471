import React from 'react';

const ArrowRight = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="102.933"
    height="65.96"
    viewBox="0 0 102.933 65.96"
  >
    <path
      d="M52.818,0,49.183,5.52,85.892,29.676H0v6.608H85.892L49.183,60.439l3.635,5.52,50.115-32.98Z"
      transform="translate(0 0)"
      fill="currentColor"
    />
  </svg>
);

export default ArrowRight;
